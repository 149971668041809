import {useValtioSnapshot, valtioProxy} from '@/utils/valtio';
import {useRevalidator} from '@remix-run/react';
import React from 'react';
import {useEffect, type FC} from 'react';

// this exists solely to allow redirects outside of remix context
export const shouldRevalidateRootProxy = valtioProxy<boolean>(false);
export default function revalidateRoot() {
  shouldRevalidateRootProxy.value = true;
}

export const RootRevalidator: FC = React.memo(function RootRevalidator() {
  const revalidator = useRevalidator();
  const shouldRevalidateRoot = useValtioSnapshot(shouldRevalidateRootProxy);

  // HACK
  useEffect(() => {
    if (shouldRevalidateRoot) {
      revalidator.revalidate();
      shouldRevalidateRootProxy.value = false;
    }
  }, [shouldRevalidateRoot, revalidator]);

  return null;
});
