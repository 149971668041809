import clsx from 'clsx';
import '@/components/core/Status.css';
import type {CSSProperties, FC, ReactNode} from 'react';
import type {AnimationPhase} from '@/components/core/Animation';

export type StatusType = 'success' | 'info' | 'warning' | 'error';

export type StatusProps = {
  type: StatusType;
  content: ReactNode | (() => ReactNode);
  index: number;
  className?: string;
  animationPhase?: AnimationPhase;
  style?: CSSProperties;
};

const Status: FC<StatusProps> = function Status(props) {
  const {className, type = 'info', animationPhase = null, index} = props;
  let {content} = props;
  if (typeof content === 'function') {
    content = content();
  }

  return (
    <div
      style={{top: 50 * index}}
      className={clsx(
        'Status',
        `Status-type-${type}`,
        className,
        'absolute z-50 inline-flex items-center justify-center',
        animationPhase && `Status-animating-${animationPhase}`,
        animationPhase === 'in' && 'animate-fade-in',
        animationPhase === 'out' && 'animate-fade-out',
      )}
    >
      <div
        className={clsx(
          'px-6 xs:px-4 py-2 xs:py-2 border-2 border-solid rounded-md text-white shadow-lg',
          type === 'success' && 'bg-green-500 border-green-600',
          type === 'info' && 'bg-blue-500 border-blue-600',
          type === 'warning' && 'bg-yellow-500 border-yellow-600',
          type === 'error' && 'bg-red-500 border-red-600',
        )}
      >
        {content}
      </div>
    </div>
  );
};

export default Status;
