import Environment, {ResolveEnvironment} from './Environment';

const WebClient = ResolveEnvironment(Environment.WebClientUrl);

const WebClientUrl = new URL(WebClient);

export default {
  WebClient,
  WebClientUrl,
  ApiServer: ResolveEnvironment({
    Local: `${WebClient}/api`,
    CI: `${WebClient}/api`,
    Dev: `${WebClient}/api`,
    Prod: `${WebClient}/api`,
  }),
  ApiServerInternal: ResolveEnvironment({
    Local: 'http://livan.localdomain:3001',
    CI: 'http://server-api',
    Dev: 'http://server-api-dev-1758292538.us-west-2.elb.amazonaws.com',
    Prod: 'TODO',
  }),
  WebSocketServer: ResolveEnvironment({
    Local: `${WebClient}/ws`,
    CI: `${WebClient}/ws`,
    Dev: `${WebClient}/ws`,
    Prod: 'TODO',
  }),
  WebSocketServerInternal: ResolveEnvironment({
    Local: 'http://livan.localdomain:3002',
    CI: 'http://server-ws',
    Dev: 'http://ecs-dev-server-ws-dev-977245797.us-west-2.elb.amazonaws.com',
    Prod: 'TODO',
  }),
};
