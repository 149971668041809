import clsx from 'clsx';
import {useEffect, useRef, useState, type CSSProperties, type FC, type ReactNode} from 'react';
import {AnimateOutDurationMs, type AnimationPhase} from '@/components/core/Animation';
import LoadingIndicator from '@/components/core/LoadingIndicator';
import {useValtioSnapshot} from '@/utils/valtio';
import {webSocketStateProxy} from '@/utils/websocketsTrpc';
import {CheckIcon} from '@heroicons/react/24/outline';

export type StatusType = 'success' | 'info' | 'warning' | 'error';

export type StatusProps = {
  className?: string;
};

type DisplayState = 'reconnecting' | 'reconnected' | 'animating-out' | null;

const ReconnectBanner: FC<StatusProps> = function ReconnectBanner(props) {
  const {className} = props;
  const {isReconnecting} = useValtioSnapshot(webSocketStateProxy);
  const [displayState, setDisplayState] = useState<DisplayState>(null);
  useEffect(() => {
    if (isReconnecting) {
      setTimeout(() => {
        // don't display banner right away because brief disconnections will happen often
        if (!webSocketStateProxy.value.isReconnecting) {
          return;
        }
        setDisplayState('reconnecting');
      }, 2500);
    } else if (displayState === 'reconnecting') {
      setDisplayState('reconnected');
      setTimeout(() => {
        setDisplayState('animating-out');
        setTimeout(() => {
          setDisplayState(null);
        }, AnimateOutDurationMs);
      }, 2000);
    }
  }, [isReconnecting, displayState]);

  if (!displayState) {
    return null;
  }

  return (
    <div
      className={clsx(
        'ReconnectBanner',
        className,
        'fixed z-50 inline-flex items-center justify-center top-0 w-full text-lg p-1',
        displayState === 'reconnecting' && 'animate-drop-in bg-yellow-500',
        displayState === 'reconnected' && 'animate-drop-in bg-green-500',
        displayState === 'animating-out' && 'animate-drop-out bg-green-500',
      )}
    >
      {displayState === 'reconnecting' ? (
        <LoadingIndicator
          color="black"
          size="sm"
          className="mr-2"
        />
      ) : (
        <CheckIcon className="mr-2 size-5" />
      )}
      {displayState === 'reconnecting' ? 'Reconnecting...' : 'Reconnected!'}
    </div>
  );
};

export default ReconnectBanner;
