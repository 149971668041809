import {AnimateOutDurationMs} from '@/components/core/Animation';
import type {StatusProps} from '@/components/core/Status';
import Status from '@/components/core/Status';
import Environment from '@/config/Environment';
import {useValtioSnapshot, valtioProxy} from '@/utils/valtio';
import clsx from 'clsx';

type StatusSpecProps = Pick<StatusProps, 'type' | 'content'>;

type StatusSpec = {
  id: number;
  props: Omit<StatusProps, 'index'>;
};

let statusId = 0;

const baseDisplayDuration = 2805;
const DisplayDuration = Environment.IsPlaywright ? baseDisplayDuration / 3 : baseDisplayDuration; // speeds up tests

const statusSpecProxiesProxy = valtioProxy<{value: StatusSpec | null}[]>([]);

export function displayStatus(props: StatusSpecProps) {
  const id = ++statusId;
  const statusSpec: StatusSpec = {
    id,
    props: {
      ...props,
      animationPhase: 'in',
    },
  };
  const statusSpecProxy = valtioProxy(statusSpec);
  statusSpecProxiesProxy.value = [...statusSpecProxiesProxy.value, statusSpecProxy];

  setTimeout(() => {
    statusSpecProxy.value.props.animationPhase = 'out';
    statusSpecProxy.value = {...statusSpecProxy.value};

    setTimeout(() => {
      let otherStatusesExist = false;
      for (let i = 0; i < statusSpecProxiesProxy.value.length; i++) {
        const newStatus = statusSpecProxiesProxy.value[i];
        if (id === newStatus.value?.id) {
          statusSpecProxiesProxy.value[i].value = null;
        } else if (newStatus.value) {
          otherStatusesExist = true;
        }
      }
      if (!otherStatusesExist) {
        // empty entire array if none are left to start positioning over
        statusSpecProxiesProxy.value = [];
      }
      statusSpecProxiesProxy.value = [...statusSpecProxiesProxy.value];
    }, AnimateOutDurationMs);
  }, DisplayDuration);
}

export default function StatusContext({
  children,
}: {
  container?: Element | DocumentFragment;
  children: any;
}) {
  const statusSpecProxies = useValtioSnapshot(statusSpecProxiesProxy);
  return (
    <>
      <div className="StatusContext fixed flex flex-col items-center justify-center z-50 w-full">
        {statusSpecProxies.map((statusSpecProxy, i) => {
          const id = statusSpecProxy.value?.id;
          if (!statusSpecProxy.value) {
            return null;
          }
          return (
            <Status
              key={id || i}
              className={clsx('mb-1 mt-2 md:mt-4')}
              index={i}
              style={{marginTop: 40 * i}}
              {...(statusSpecProxy.value.props as Readonly<StatusSpec['props']>)}
            />
          );
        })}
      </div>
      {children}
    </>
  );
}
