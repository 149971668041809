import EventEmitter from '@/events/EventEmitter';
import type {GlobalEventEmitterEvents} from '@/events/GlobalEventEmitterEvents';

class _GlobalEventEmitter extends EventEmitter {
  override emit<T extends GlobalEventEmitterEvents['type']>(
    eventName: T,
    data: Extract<GlobalEventEmitterEvents, {type: T}>['data'],
    clientContext?: {optimisticId: string},
  ): boolean {
    return super.emit(eventName, {
      data,
      clientContext,
    });
  }
}

const GlobalEventEmitter = new _GlobalEventEmitter();
export default GlobalEventEmitter;
