import {displayStatus} from '@/context/StatusContext';

type CopyFn = (text: string) => Promise<boolean>;

export function useCopyToClipboard(): CopyFn {
  const copy: CopyFn = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (error) {
      return false;
    }
  };

  return copy;
}

export function useCopyToClipboardWithStatusContext() {
  const copy = useCopyToClipboard();
  return async (text) => {
    if (await copy(text)) {
      displayStatus({
        type: 'info',
        content: 'Copied to clipboard!',
      });
    } else {
      displayStatus({
        type: 'error',
        content: 'Unable to copy to clipboard. Check browser permissions?',
      });
    }
  };
}
