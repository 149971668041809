export default class EventEmitter {
  target = new EventTarget();

  on(eventName: string, listener: (event: Event) => void) {
    return this.target.addEventListener(eventName, listener);
  }
  once(eventName: string, listener: (event: Event) => void) {
    return this.target.addEventListener(eventName, listener, {once: true});
  }
  off(eventName: string, listener: (event: Event) => void) {
    return this.target.removeEventListener(eventName, listener);
  }
  emit(eventName: string, detail: any) {
    return this.target.dispatchEvent(new CustomEvent(eventName, {detail, cancelable: true}));
  }
}
