import ArrayUtils from '@/ArrayUtils';
import type {Role} from '@/model/role/types';

export const AdminRole: Role = {
  priority: 500,
  name: 'Admin',
  description: 'Admins can manage all aspects of an org',
};

export const ManagerRole: Role = {
  priority: 400,
  name: 'Manager',
  description: 'Managers can manage roles on users in an org',
};

export const StylistRole: Role = {
  priority: 300,
  name: 'Stylist',
  description: 'Stylists can manage boards and invite users to an org',
};

export const ClientRole: Role = {
  priority: 200,
  name: 'Client',
  description: 'Clients can like, comment, and view published content within an org',
};

export const ViewerRole: Role = {
  priority: 100,
  name: 'Viewer',
  description: 'Viewers have read-only access to published content within an org',
};

export const AllRoles = [AdminRole, ManagerRole, StylistRole, ClientRole, ViewerRole];

export const RolesByPriority = ArrayUtils.ToObjectByProperty(AllRoles, 'priority');
