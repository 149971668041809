import clsx from 'clsx';
import type {BaseComponentProps} from '@/components/types';
import type {FC} from 'react';
import {
  ExclamationCircleIcon,
  EyeSlashIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import Button from '@/components/core/Button';
import {useNavigate} from '@remix-run/react';

const emptyStateMetaByType = {
  Forbidden: {
    Icon: EyeSlashIcon,
    title: 'Forbidden',
    description: 'You do not have permissions to view this page.',
  },
  NoResults: {
    Icon: MagnifyingGlassIcon,
    title: 'No results',
    description: 'There are no results for this search.',
  },
  PageNotFound: {
    Icon: XCircleIcon,
    title: 'Not found',
    description: 'This page does not exist.',
  },
  GenericError: {
    Icon: ExclamationCircleIcon,
    title: 'Error',
    description: 'An unexpected error occurred loading this page.',
  },
};

type Props = BaseComponentProps & {
  type: keyof typeof emptyStateMetaByType;
  title?: string;
  description?: string;
  buttonTo?: string;
  buttonText?: string;
};

const EmptyState: FC<Props> = function EmptyState(props) {
  const {className, type, buttonTo, buttonText} = props;
  const emptyStateMeta = emptyStateMetaByType[type] || emptyStateMetaByType.GenericError;

  const {title = emptyStateMeta.title, description = emptyStateMeta.description} = props;
  const {Icon} = emptyStateMeta;

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div
      className={clsx(
        className,
        'flex flex-col justify-center items-center w-full gap-4 py-6 sm:py-10 px-4 sm:px-6',
      )}
    >
      <Icon className="size-32" />
      <h2>{title}</h2>
      <div>{description}</div>
      <div>
        {buttonTo ? <Button to={buttonTo}>{buttonText}</Button> : <Button to="..">Go back</Button>}
      </div>
    </div>
  );
};

export default EmptyState;
