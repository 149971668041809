import {watch} from 'valtio/utils';

import {useRef} from 'react';
// this is the only place we allow this
// eslint-disable-next-line no-restricted-imports
import {proxy, ref, subscribe, useSnapshot} from 'valtio';

export function when(predicate: (get: Parameters<Parameters<typeof watch>[0]>[0]) => boolean) {
  let resolvePromise;
  let rejectPromise;
  const promise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });

  const unsubscribe = watch((get) => {
    const value = predicate(get);
    if (value) {
      resolvePromise(value);
    }
  });

  return promise.finally(() => {
    unsubscribe();
  });
}

export function valtioRef<T extends object>(data: T) {
  const refData = ref(data);
  return refData;
}

type ValtioProxy<T> = {value: T};

export function valtioProxy<T>(data: T): ValtioProxy<T> {
  const proxyState = proxy({value: data});
  return proxyState;
}

export function useValtioSnapshot<T>(proxy: ValtioProxy<T>) {
  const {value} = useSnapshot(proxy);
  return value;
}

export function useValtioProxy<T>(data: T) {
  const proxyState = useRef(valtioProxy(data)).current;
  return [useSnapshot(proxyState), proxyState] as [Readonly<{value: Readonly<T>}>, ValtioProxy<T>];
}
