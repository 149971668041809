import type {Board, BoardId, BoardIdObj} from '@/model/board/types';
import type {
  BoardItem,
  BoardItemId,
  BoardItemIdObj,
  BoardItemWithOrder,
} from '@/model/board_item/types';
import type {BoardItemOrder} from '@/model/board_item_order/types';
import type {UserId} from '@/model/common/types';
import type {ExtendedOrg, Org, OrgId, OrgIdObj} from '@/model/org/types';

const SocketUpdateEventTypes = {
  Org: {
    Created: 'org-created',
    Updated: 'org-updated',
    Deleted: 'org-deleted',
  },
  Board: {
    Created: 'board-created',
    Updated: 'board-updated',
    Published: 'board-published',
    Unpublished: 'board-unpublished',
    Deleted: 'board-deleted',
  },
  BoardItem: {
    Created: 'board-item-created',
    Updated: 'board-item-updated',
    Deleted: 'board-item-deleted',
  },
  BoardItemOrder: {
    Updated: 'board-item-order-updated',
  },
} as const;

export default SocketUpdateEventTypes;

export type SocketUpdateEvent =
  | {
      type: typeof SocketUpdateEventTypes.Org.Created;
      data: ExtendedOrg;
    }
  | {
      type: typeof SocketUpdateEventTypes.Org.Updated;
      data: Org;
    }
  | {
      type: typeof SocketUpdateEventTypes.Org.Deleted;
      data: Org;
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Created;
      data: Board;
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Updated;
      data: Board;
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Published;
      data: Board;
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Unpublished;
      data: Board;
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Deleted;
      data: Board;
    }
  | {
      type: typeof SocketUpdateEventTypes.BoardItem.Created;
      data: BoardItemWithOrder;
    }
  | {
      type: typeof SocketUpdateEventTypes.BoardItem.Updated;
      data: BoardItem;
    }
  | {
      type: typeof SocketUpdateEventTypes.BoardItem.Deleted;
      data: BoardItem;
    }
  | {
      type: typeof SocketUpdateEventTypes.BoardItemOrder.Updated;
      data: Pick<BoardItemOrder, 'boardItemId' | 'boardItemType'> & {
        before: Pick<BoardItemOrder, 'order' | 'parentBoardItemId'>;
        after: Pick<BoardItemOrder, 'order' | 'parentBoardItemId'>;
      };
    };
