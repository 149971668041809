import Urls from '@/config/Urls';
import ErrorLogger from '@/ErrorLogger';
import GlobalEventEmitter from '@/events/GlobalEventEmitter';
import WebSocketEventEmitter from '@/events/WebSocketEventEmitter';
import clientId from '@/utils/clientId';
import type {WebsocketsRouter} from '@server-ws-trpc/websocketsRouter';
import {createTRPCClient, createWSClient, wsLink} from '@trpc/client';
import type {inferRouterOutputs} from '@trpc/server';
import superjson from 'superjson';
import {valtioProxy} from '@/utils/valtio';

export const webSocketStateProxy = valtioProxy({
  isReconnecting: false,
});

// must hack in a custom impl that passes a protocol where we can store the auth token
class WebSocketWithProtocol extends WebSocket {
  constructor(url: string, protocol?: string) {
    console.assert(!protocol, 'protocol was provided via trpc but it should not be!');
    super(url, [`clientId_${clientId}`]);

    const existingOnError = this.onerror;

    this.onerror = (ev) => {
      webSocketStateProxy.value.isReconnecting = true;
      existingOnError?.call(this, ev);
    };
  }
}

export type RouterOutputs = inferRouterOutputs<WebsocketsRouter>;

let wsClient: ReturnType<typeof createWSClient> | null = null;

export async function initializeWebsocketSubscription() {
  closeWebsocketSubscription();

  wsClient = createWSClient({
    url: `${Urls.WebSocketServer}/trpc`,
    // need type annotation due to composite typescript project referencing server project
    WebSocket: WebSocketWithProtocol as unknown as typeof WebSocket,
    keepAlive: {
      enabled: true,
      intervalMs: 30000,
    },
    onClose() {
      webSocketStateProxy.value.isReconnecting = true;
    },
  });

  const websocketsTrpc = createTRPCClient<WebsocketsRouter>({
    links: [
      wsLink<WebsocketsRouter>({
        client: wsClient,
        transformer: superjson,
      }),
    ],
  });

  const websocketSubscription = websocketsTrpc.user.subscribe(
    {},
    {
      // must set reconnection here instead of on websocket reconnect because we still have to request the subscription even after websocket connects
      onStarted() {
        if (webSocketStateProxy.value.isReconnecting) {
          webSocketStateProxy.value.isReconnecting = false;
          GlobalEventEmitter.emit('websocket-reconnected', undefined);
        }
      },
      onData(event) {
        WebSocketEventEmitter.emit(event.type, event.data);
      },
      onError(err) {
        ErrorLogger.Log(err);

        // const lastMessageEventId = messages?.at(-1)?.id;
        // if (lastMessageEventId) {
        //   // We've lost the connection, let's resubscribe from the last message
        //   setLastEventId(lastMessageEventId);
        // }
      },
    },
  );
}

export function closeWebsocketSubscription() {
  if (wsClient) {
    wsClient.close();
    wsClient = null;
  }
}
