import type {Board, BoardId, BoardIdObj} from '@/model/board/types';
import type {
  BoardItemId,
  BoardItemWithOrder,
  ClientBoardItemWithOrder,
} from '@/model/board_item/types';
import BoardItemType from '@/model/board_item_type/types';
import type {ExtendedOrg, OrgIdObj} from '@/model/org/types';
import {type Org, type OrgId} from '@/model/org/types';
import type {User} from '@/model/user/types';
import ObjectUtils from '@/ObjectUtils';
import {EntityArraySignalsByIdStore, EntitySignalsByIdStore} from '@/state/stores';
import {when} from '@/utils/signals';
import type {Signal} from '@preact/signals-react';
import {computed, signal} from '@preact/signals-react';

export const currentUserSignal = signal<User | null | undefined>(undefined);
export const userOrgsSignal = signal<ExtendedOrg[] | null | undefined>(undefined);
export const boardsByOrgIdSignal = signal<Record<OrgId, Signal<Signal<Board>[]>>>({});
export const boardSignalsById: Record<BoardId, Signal<Board | null | undefined>> = {};
export const orgSignalsById: Record<OrgId, Signal<Org | null | undefined>> = {};

export const orgSignalsByIdStore = new EntitySignalsByIdStore<OrgIdObj, Org>();
export const boardSignalsByIdStore = new EntitySignalsByIdStore<BoardIdObj, Board>();
export const boardsSignalsByOrgIdStore = new EntityArraySignalsByIdStore<
  OrgIdObj,
  BoardIdObj,
  Board
>(boardSignalsByIdStore);

const nullGroup = {
  id: 'null',
  type: BoardItemType.Group,
};

export const emptyGroups = [nullGroup];
// @ts-expect-error ignore empty groups for now
export const groupsSignal = signal<ClientBoardItemWithOrder[]>(emptyGroups);
export const groupsByGroupIdSignal = computed(() => {
  return ObjectUtils.GroupByKey(groupsSignal.value);
});

export const emptyItemsByGroupId = {
  ['null']: [],
};
export const itemsByGroupIdSignal =
  signal<Record<BoardItemId | 'null', ClientBoardItemWithOrder[]>>(emptyItemsByGroupId);

export const itemsByItemIdSignal = computed(() => {
  const itemsByItemId = Object.values(itemsByGroupIdSignal.value).reduce((acc, items) => {
    Object.assign(acc, ObjectUtils.GroupByKey(items));
    return acc;
  }, {});
  return itemsByItemId;
});

export const GetCurrentUser = async () => {
  await when(() => currentUserSignal.value !== undefined);
  return currentUserSignal.value as User | null;
};
