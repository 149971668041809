import type {Branded, Identifiable} from '@/model/common/types';
import assert from '@/utils/assert';
import type {Signal} from '@preact/signals-react';
import {signal} from '@preact/signals-react';

type IdKey = Branded<string, 'IdKey'>;
abstract class BaseEntitiesByIdStore<IdObj extends Record<string, string | number>> {
  protected constructIdKey(idObj: Record<string, any>): IdKey {
    const entries = Object.entries(idObj);
    if (entries.length === 1) {
      // optimization
      const idKey = entries[0][1].toString() as IdKey;
      return idKey;
    }
    const idKey = entries
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => {
        return `${key}:${value}`;
      })
      .join('|') as IdKey;
    return idKey;
  }
}

export class EntitySignalsByIdStore<
  IdObj extends Record<string, string | number>,
  Entity extends Identifiable<IdObj>,
> extends BaseEntitiesByIdStore<IdObj> {
  private entitiesByIdKey: Record<IdKey, Signal<Entity | null | undefined>> = {};

  private set(idKey: IdKey, entity: Entity | null | undefined) {
    if (!this.entitiesByIdKey[idKey]) {
      this.entitiesByIdKey[idKey] = signal(entity);
    } else {
      this.entitiesByIdKey[idKey].value = entity;
    }
    return this.get(idKey);
  }

  private get(idKey: IdKey) {
    return this.entitiesByIdKey[idKey];
  }

  getById(idObj: IdObj): Signal<Entity | null | undefined> {
    const idKey = this.constructIdKey(idObj);
    if (!this.get(idKey)) {
      this.entitiesByIdKey[idKey] = signal(undefined);
    }
    return this.get(idKey);
  }

  create(idObj: IdObj, entity: Entity): Signal<Entity> {
    const idKey = this.constructIdKey(idObj);
    const entitySignal = this.set(idKey, entity) as Signal<Entity>;
    return entitySignal;
  }

  update(idObj: IdObj, partialEntity: Partial<Entity>): Signal<Entity> | null {
    const idKey = this.constructIdKey(idObj);
    const entitySignal = this.get(idKey);
    const entity = entitySignal.peek();
    assert(entity, `entity is required`);
    const entityToUpdate = {
      ...entity,
      ...partialEntity,
    };
    const updatedEntitySignal = this.set(idKey, entityToUpdate) as Signal<Entity>;
    return updatedEntitySignal;
  }

  delete(idObj: IdObj): void {
    const idKey = this.constructIdKey(idObj);
    this.set(idKey, null);
  }
}

export class EntityArraySignalsByIdStore<
  EntityAIdObj extends Record<string, string | number>,
  EntityBIdObj extends Record<string, string | number>,
  EntityB extends Identifiable<EntityBIdObj>,
> extends BaseEntitiesByIdStore<EntityAIdObj> {
  private entitiesByIdKey: Record<IdKey, Signal<Signal<EntityB>[] | null | undefined>> = {};

  entitySignalsByIdStore: EntitySignalsByIdStore<EntityBIdObj, EntityB> | null = null;

  constructor(entitySignalsByIdStore?: EntitySignalsByIdStore<EntityBIdObj, EntityB>) {
    super();
    if (entitySignalsByIdStore) {
      this.entitySignalsByIdStore = entitySignalsByIdStore;
    }
  }

  private set(idKey: IdKey, entity: Signal<EntityB>[] | null | undefined) {
    if (!this.entitiesByIdKey[idKey]) {
      this.entitiesByIdKey[idKey] = signal(entity);
    } else {
      this.entitiesByIdKey[idKey].value = entity;
    }
    return this.entitiesByIdKey[idKey];
  }

  getById(idObj: EntityAIdObj): Signal<Signal<EntityB>[] | null | undefined> {
    const idKey = this.constructIdKey(idObj);
    if (!this.entitiesByIdKey[idKey]) {
      this.entitiesByIdKey[idKey] = signal(undefined);
    }
    return this.entitiesByIdKey[idKey];
  }

  create(idObj: EntityAIdObj, entities: EntityB[]): Signal<Signal<EntityB>[]> {
    const idKey = this.constructIdKey(idObj);
    const entitySignals = entities.map((entity) => {
      if (this.entitySignalsByIdStore) {
        // @ts-expect-error this won't work when we get to compound keys, need to construct key dynamically
        return this.entitySignalsByIdStore.create({id: entity.id}, entity);
      } else {
        return signal(entity);
      }
    });
    return this.set(idKey, entitySignals) as Signal<Signal<EntityB>[]>;
  }

  createItem(idObj: EntityAIdObj, entity: EntityB): Signal<EntityB> {
    const idKey = this.constructIdKey(idObj);
    let entitySignal;
    if (this.entitySignalsByIdStore) {
      // @ts-expect-error this won't work when we get to compound keys, need to construct key dynamically
      entitySignal = this.entitySignalsByIdStore.create({id: entity.id}, entity);
    } else {
      entitySignal = signal(entity);
    }

    const existing = this.entitiesByIdKey[idKey]?.value;
    if (existing) {
      this.set(idKey, [...existing, entitySignal]);
    } else {
      this.set(idKey, [entitySignal]);
    }
    return entitySignal;
  }

  updateItem(
    idObj: EntityAIdObj,
    partialEntity: EntityBIdObj & Partial<Omit<EntityB, keyof EntityBIdObj>>,
  ): Signal<EntityB> {
    const idKey = this.constructIdKey(idObj);
    let entitySignal: Signal<EntityB>;
    if (this.entitySignalsByIdStore) {
      // @ts-expect-error this won't work when we get to compound keys, need to construct key dynamically
      entitySignal = this.entitySignalsByIdStore.update({id: partialEntity.id}, partialEntity);
    } else {
      const entitySignals = this.entitiesByIdKey[idKey]?.value;
      assert(entitySignals, `entitySignals is required`);
      entitySignal = entitySignals.find((entitySignal) => {
        return entitySignal.peek().id === partialEntity.id;
      })!;
      assert(entitySignal, `entitySignal is required`);
      entitySignal.value = {
        ...entitySignal.value,
        ...partialEntity,
      };
    }

    return entitySignal;
  }

  delete(idObj: EntityAIdObj): void {
    const idKey = this.constructIdKey(idObj);
    this.set(idKey, null);
  }

  deleteItem(idObjA: EntityAIdObj, idObjB: EntityBIdObj): void {
    const idKey = this.constructIdKey(idObjA);

    const filtered = this.entitiesByIdKey[idKey].value!.filter((entitySignal) => {
      const entity = entitySignal.peek();
      return !Object.entries(idObjB).every(([key, value]) => {
        return entity[key] === value;
      });
    });
    this.set(idKey, filtered);
  }
}
