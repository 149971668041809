import EventEmitter from '@/events/EventEmitter';
import type {SocketUpdateEvent} from '@/events/SocketUpdateEvents';

class WebSocketEventEmitter extends EventEmitter {
  override emit<T extends SocketUpdateEvent['type']>(
    eventName: T,
    data: Extract<SocketUpdateEvent, {type: T}>['data'],
    clientContext?: {optimisticId: string},
  ): boolean {
    return super.emit(eventName, {
      data,
      clientContext,
    });
  }
}

const webSocketEventEmitter = new WebSocketEventEmitter();
export default webSocketEventEmitter;
