import ArrayUtils from '@/ArrayUtils';
import type {Role} from '@/model/role/types';
import * as SharedRoles from '@shared/model/role/default';
import type {ColorStyle as PillColorStyle} from '@/components/core/Pill';

type ClientRole = Role & {
  colorStyle: PillColorStyle;
};

export const AdminRole: ClientRole = {
  ...SharedRoles.AdminRole,
  colorStyle: 'red',
};

export const ManagerRole: ClientRole = {
  ...SharedRoles.ManagerRole,
  colorStyle: 'orange',
};

export const StylistRole: ClientRole = {
  ...SharedRoles.StylistRole,
  colorStyle: 'yellow',
};

export const ClientRole: ClientRole = {
  ...SharedRoles.ClientRole,
  colorStyle: 'blue',
};

export const ViewerRole: ClientRole = {
  ...SharedRoles.ViewerRole,
  colorStyle: 'gray',
};

export const AllRoles = [AdminRole, ManagerRole, StylistRole, ClientRole, ViewerRole];

export const RolesByPriority = ArrayUtils.ToObjectByProperty(AllRoles, 'priority');
