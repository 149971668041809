import React from 'react';

import Urls from '@/config/Urls';
import ErrorLogger from '@/ErrorLogger';
import * as Sentry from '@sentry/react';

import {RemixBrowser} from '@remix-run/react';
import {startTransition, StrictMode} from 'react';
import {hydrateRoot} from 'react-dom/client';
import type {Temporal} from 'temporal-polyfill';
import {toTemporalInstant} from 'temporal-polyfill';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from '@remix-run/react';
import {handleGenericClientError} from '@/utils/errors';

window._livanVersion = __GIT_HASH__;

declare global {
  interface Date {
    toTemporalInstant(): Temporal.Instant;
  }
}
Date.prototype.toTemporalInstant = toTemporalInstant;

ErrorLogger.Initialize(Sentry, {
  dsn: Urls.SentryDsn,
  replaysSessionSampleRate: 1,
  integrationsToAdd: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

window.addEventListener('error', (event) => {
  event.preventDefault();
  handleGenericClientError(event.error);
});

window.addEventListener('unhandledrejection', (event) => {
  event.preventDefault();
  handleGenericClientError(event.reason);
});

// voodoo to prevent react hydration errors. supposedly react 19 will remove the need for this
// @see https://github.com/remix-run/remix/issues/4822#issuecomment-2167950638
// @see https://gist.github.com/OnurGvnc/31f03f0d5237b78224aa083493fda645#file-clearbrowserextensioninjectionsbeforehydration-ts-L5-L40
function clearBrowserExtensionInjectionsBeforeHydration() {
  document
    .querySelectorAll(
      [
        'html > *:not(body, head)',
        'script[src*="extension://"]',
        'link[href*="extension://"]',
      ].join(', '),
    )
    .forEach((s) => {
      s.parentNode?.removeChild(s);
    });

  const $targets = {
    html: {
      $elm: document.querySelector('html')!,
      allowedAttributes: ['lang', 'dir', 'class'],
    },
    head: {
      $elm: document.querySelector('head')!,
      allowedAttributes: [''],
    },
    body: {
      $elm: document.querySelector('body')!,
      allowedAttributes: ['class'],
    },
  };

  Object.entries($targets).forEach(([targetName, target]) => {
    target.$elm.getAttributeNames().forEach((attr) => {
      if (!target.allowedAttributes.includes(attr)) {
        target.$elm.removeAttribute(attr);
      }
    });
  });
}

function hydrate() {
  clearBrowserExtensionInjectionsBeforeHydration();

  startTransition(() => {
    const root = hydrateRoot(document, <RemixBrowser />, {
      // attempt to fix hydration errors
      // https://github.com/remix-run/remix/issues/4822#issuecomment-2167950638
      onRecoverableError() {
        root.render(<RemixBrowser />);
      },
    });
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
