import EnvironmentVariables from '@/EnvironmentVariables';

let IsLocal = false;
let IsCI = false;
let IsDev = false;
let IsProd = false;

const WebClientUrl = {
  Local: 'https://livan.localdomain:5173',
  CI: 'https://livan.localdomain:5174',
  Dev: 'https://www.sbc-dev-test.com',
  Prod: 'TODO',
};

let CurrentEnvironment = EnvironmentVariables.ENVIRONMENT as 'local' | 'ci' | 'dev' | 'prod';

const IsWeb = typeof window !== 'undefined';

if (
  IsWeb &&
  !CurrentEnvironment // this will be defined on web if in integration tests
) {
  // this is running on client
  const {origin} = window.location;
  if (origin === WebClientUrl.Local) {
    IsLocal = true;
    CurrentEnvironment = 'local';
  } else if (origin === WebClientUrl.CI) {
    IsCI = true;
    CurrentEnvironment = 'ci';
  } else if (origin === WebClientUrl.Dev) {
    IsDev = true;
    CurrentEnvironment = 'dev';
  } else if (origin === WebClientUrl.Prod) {
    IsProd = true;
    CurrentEnvironment = 'prod';
  } else {
    throw new Error(
      `Unable to detect environment from \`window.location.origin\`: ${window.location.origin}`,
    );
  }
} else {
  // this is running on server
  if (CurrentEnvironment === 'local') {
    IsLocal = true;
  } else if (CurrentEnvironment === 'ci') {
    IsCI = true;
  } else if (CurrentEnvironment === 'dev') {
    IsDev = true;
  } else if (CurrentEnvironment === 'prod') {
    IsProd = true;
  } else {
    throw new Error(
      `Unable to detect environment from \`EnvironmentVariables.ENVIRONMENT\`: ${EnvironmentVariables.ENVIRONMENT}`,
    );
  }
}

const IsPlaywright = typeof window !== 'undefined' && !!window._isPlaywright;

const Environment = {
  CurrentEnvironment,
  IsPlaywright,
  IsLocal,
  IsCI,
  IsDev,
  IsProd,
  WebClientUrl,
  IsWeb,
  IsClient: IsWeb, // TODO add support for native detection
  IsServer: !IsWeb,
};

export type ResolveEnvironmentParams<T> = {
  Local: T;
  CI: T;
  Dev: T;
  Prod: T;
};

function ResolveEnvironment<T>({Local, CI, Dev, Prod}: ResolveEnvironmentParams<T>) {
  let value: T;
  if (Environment.IsLocal) {
    value = Local;
  } else if (Environment.IsCI) {
    value = CI;
  } else if (Environment.IsDev) {
    value = Dev;
  } else if (Environment.IsProd) {
    value = Prod;
  } else {
    throw new Error(
      'Environment undetectable. Did you add an environment and not update ResolveEnvironment?',
    );
  }

  return value;
}

ResolveEnvironment.WithDefault = function WithDefault<T>(
  defaultValue: T,
  params?: Partial<ResolveEnvironmentParams<T>>,
) {
  let value = ResolveEnvironment((params || {}) as ResolveEnvironmentParams<T>) as T | undefined;
  if (typeof value === 'undefined') {
    value = defaultValue;
  }
  return value;
};

export {ResolveEnvironment};

export default Environment;
