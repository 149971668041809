import ErrorLogger from '@/ErrorLogger';
import {useRouteError} from '@remix-run/react';
import {captureRemixErrorBoundaryError} from '@sentry/remix';

export default function LivanErrorBoundary() {
  const error = useRouteError() as Error;
  ErrorLogger.Log(
    error,
    {
      tags: {
        type: 'error-boundary',
      },
    },
    captureRemixErrorBoundaryError,
  );

  return <div>Oops! An error occurred. Please try again later</div>;
}
